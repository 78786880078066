<app-header></app-header>
<app-hero *ngIf="isHome"></app-hero>
<app-slider-hero *ngIf="!isHome"></app-slider-hero>
<app-loader [load]="loader"></app-loader>
<div class="banner-page" *ngIf="!isHome && bannerImage">
  <img
    src="{{bannerImage}}"
    alt=""
  />
</div>

<router-outlet></router-outlet>

<app-footer></app-footer>

