<section class="social-networks-footer">
  <div class="container">
    <div class="row center-xs middle-xs around-xs">
      <!-- <div class="col-xs-12 col-sm-2"></div>
      <div class="col-xs-12 col-sm-2">
        <a href="https://web.whatsapp.com" target="_blank">
          <figure><img src="../../../../assets/images/icons/icon-whatsapp2.svg" alt="WhatsApp"></figure>
         310 890 8900
        </a>
      </div>
      <div class="col-xs-12 col-sm-2">
        <a href="https://facebook.com" target="_blank">
          <figure><img src="../../../../assets/images/icons/icon-facebook2.svg" alt="Facebook"></figure>
         /industriasexplorer
        </a>
      </div>
      <div class="col-xs-12 col-sm-2">
        <a href="https://instagram.com" target="_blank">
          <figure><img src="../../../../assets/images/icons/icon-instagram2.svg" alt="Instagram"></figure>
         @industriasexplorer
        </a>
      </div>
      <div class="col-xs-12 col-sm-2"></div> -->
      <div class="col-xs-12 center-xs">
        <figure class="logo-tuv">
          <img src="../../../../assets/images/logo-tuv.jpg" alt="TÜV">
        </figure>
      </div>
    </div>
  </div>
</section>
<footer>
  <div class="container">
    <div class="row between-xs">
      <div class="col-xs-12 col-md-4 row middle-xs center-xs">
        <a routerLink="/" class="logo-footer">
          <figure><img src="../../../../assets/images/logo/logo-white.svg" alt="logotipo Explorer"></figure>
        </a>
      </div>
      <div class="col-xs-12 col-sm-6 col-md-2 row center-xs start-md menu-footer">
        <div>
          <h5>MAPA DEL SITIO</h5>
          <ul>
            <li><a routerLink="/quienes-somos" routerLinkActive="active">Nosotros</a></li>
            <li><a routerLink="/servicios" routerLinkActive="active">Servicios</a></li>
            <li><a routerLink="/alquiler" routerLinkActive="active">Alquiler</a></li>
            <li><a routerLink="/eventos" routerLinkActive="active">Eventos</a></li>
            <li><a routerLink="/clientes" routerLinkActive="active">Clientes</a></li>
            <li><a routerLink="/contacto" routerLinkActive="active">Contacto</a></li>
          </ul>
        </div>
      </div>
      <div class="col-xs-12 col-sm-6 col-md-3 row center-xs start-md planta-footer">
        <div>
          <h5>PLANTA</h5>
          <p>Av. las Américas 20 - 60</p>
          <p>Duitama - Boyacá - Colombia</p>
          <h5>TELÉFONOS</h5>
          <p>(57) 8-7631252</p>
          <p>8 - 7631254</p>
          <p>8-7602661</p>
        </div>
      </div>
      <div class="col-xs-12 col-md-3 row center-xs start-md contact-footer">
        <div>
          <h5>CORREO</h5>
          <div class="mails-footer">
            <a href="mailto:gerencia@industriasexplorer.com" target="_blank">gerencia@industriasexplorer.com</a>
            <a href="mailto:secretaria@industriasexplorer.com" target="_blank">secretaria@industriasexplorer.com</a>
          </div>
          <div class="icons-footer row center-xs start-md middle-xs">
            <div class="col-xs-4 col-sm-2">
              <a href="https://api.whatsapp.com/send?phone=573124804407&text=Buen%20d%C3%ADa,%20quisiera%20saber%20sobre" target="_blank">
                <figure><img src="../../../../assets/images/icons/icon-whatsapp3.svg" alt="WhatsApp"></figure>
              </a>
            </div>
            <div class="col-xs-4 col-sm-2">
              <a href="https://www.facebook.com/IndustriasExplorerSAS/" target="_blank">
                <figure><img src="../../../../assets/images/icons/icon-facebook3.svg" alt="Facebook"></figure>
              </a>
            </div>
            <div class="col-xs-4 col-sm-2">
              <a href="https://www.instagram.com/industrias_explorer/" target="_blank">
                <figure><img src="../../../../assets/images/icons/icon-instagram3.svg" alt="Instagram"></figure>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</footer>
